<template>
  <div class="view pa24">
    <el-button
        class="mr10"
        type="primary"
        @click="
        centerDialogVisible = true;
        bdetermine = true;
        editUpdate = false
      "
    >+ 新增分组</el-button>
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column type="selection" width="50"/>
        <el-table-column prop="groupName" align="center" label="名称" show-overflow-tooltip/>
        <el-table-column align="center" label="排序">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，排序越靠前" placement="top-right">
              <el-input v-model="scope.row.sort" min="0" @input="scope.row.sort=scope.row.sort.replace(/^0(0+)|[^\d]+/g,'')"  placeholder="数值越大，排序越靠前" @change="saveSort(scope.row)"/>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
                type="text"
                @click="
                toMemberList(scope.row);
                bEdit = false;
                editUpdate = true
              "
            >会员列表</el-button
            >
            <el-button
                type="text"
                @click="
                edit(scope.row, true);
                bEdit = false;
                editUpdate = true
              "
            >编辑</el-button
            >
            <customPopconfirm
                confirm-button-text="确定"
                k              cancel-button-text="取消"
                @confirm="del(scope.row.groupId)"
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？">
              <el-button
                  slot="reference"
                  class="ml10"
                  style="color: #fd634e"
                  type="text"
              >删除</el-button
              >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <!--    :destroy-on-close="true"-->
    <el-dialog
        title="新增分组"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="150px"
      >
        <el-form-item label="分组名称" prop="title">
          <el-input clearable v-model="ruleForm.title" maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input clearable v-model="ruleForm.sort"  @input="ruleForm.sort=ruleForm.sort.replace(/^0(0+)|[^\d]+/g,'')"  min="0" maxlength="11" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine" v-if="bdetermine"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { mapState } from 'vuex';
import customPopconfirm from "@/components/common/customPopconfirm";
import {addMemberGroup,queryMemberGroupPage,updateMemberGroup,delMemberGroup} from "@/api/members";
let ac;
export default {
  name:'membersGrouping',
  data() {
    return {
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      goodsList: [],
      goodsListCurrentPage: 1, //当前页
      goodsListPageSize: 10, //显示条数
      goodsListLoading: false, //表格加载
      goodsListTotal: 0, //总条数
      centerDialogVisible: false,
      bEdit: false,
      bdetermine: true,
      editUpdate:true,
      isUpdate:true,
      ruleForm: {
        title: "",
        sort: "",
      },
      rules: {
        title: [{ required: true, message: "请输入分组名称", trigger: "blur" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
      groupId:'',
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch:{

  },
  mounted(){
    this.queryPage();
  },

  methods: {
    async queryPage() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
      };
      try {
        this.loading = true;
        const result = await queryMemberGroupPage(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val, b) {
      let data = JSON.parse(JSON.stringify(val));
      this.centerDialogVisible = true;
      this.ruleForm.title = data.groupName;
      this.ruleForm.sort = data.sort;
      this.bdetermine = b;
      this.bEdit = b;
      this.groupId = data.groupId
    },
    /**@method 添加 */
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              groupName:this.ruleForm.title,
              sort:this.ruleForm.sort,
              isDel:0
            };
            this.$message({
              message: "正在保存",
            });
            if (this.editUpdate) {
              data.groupId = this.groupId
              await updateMemberGroup(data);
            } else {
              await addMemberGroup(data);
            }
            this.centerDialogVisible = false;
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delMemberGroup({ groupId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    async saveSort(row){
      if(row && row.sort != ""){
        let ajax = {
          groupId:row.groupId,
          sort:row.sort
        }
        const result = await updateMemberGroup(ajax);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
        await this.queryPage();
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title: "",
        sort: "",
      }),
          this.centerDialogVisible = false;
      this.fileList = []
    },
    toMemberList(row){
        this.$router.push({ path: '/toMemberGroupList',query:{groupId:row.groupId}})
    }
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  //line-height: 175px;
  text-align: center;
}
/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}
/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}
.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}
.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>






